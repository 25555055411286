import React from "react";
import {
  BooleanField,
  ChipField,
  Datagrid,
  List,
  TextField,
  Filter,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  NumberField,
} from "react-admin";
import { Empty } from "../components/Empty";
import SquareImageField from "../components/SquareImageField";
import { CurrencyField } from "../components/CurrencyField";
import { RESOURCE_TYPES } from "../config";

const resourceLabels = {
  [RESOURCE_TYPES.MEETING_ROOM]: "Meeting Rooms",
  [RESOURCE_TYPES.PRIVATE_OFFICE]: "Private Day Offices",
  [RESOURCE_TYPES.EVENT_SPACE]: "Event Spaces",
  [RESOURCE_TYPES.HOT_DESK]: "Hot Desks",
};
const currencyLabel = {
  [RESOURCE_TYPES.MEETING_ROOM]: "Price per hour",
  [RESOURCE_TYPES.PRIVATE_OFFICE]: "Daily price per desk",
  [RESOURCE_TYPES.EVENT_SPACE]: "Price per day",
  [RESOURCE_TYPES.HOT_DESK]: "Price per hour per person",
};

const ResourceFilter = (props) => (
  <Filter {...props}>
    <ReferenceArrayInput
      source="spaces"
      reference="spaces"
      label="Spaces"
      sort={{ field: "name", order: "ASC" }}
      perPage={100}
    >
      <AutocompleteArrayInput
        optionText="name"
        allowEmpty={false}
        matchSuggestion={() => true}
      />
    </ReferenceArrayInput>
  </Filter>
);

export const ResourcesList = (props) => {
  const resource = props.resource;

  let type;
  let rowClick = "show";
  let hidePricing = false;


  switch (resource) {
    default:
    case "meetingRooms":
      type = RESOURCE_TYPES.MEETING_ROOM;
      break;
    case "privateOffices":
      type = RESOURCE_TYPES.PRIVATE_OFFICE;
      break;
    case "eventSpaces":
      type = RESOURCE_TYPES.EVENT_SPACE;
      rowClick = null;
      hidePricing = true;
      break;
    case "hotDesks":
      type = RESOURCE_TYPES.HOT_DESK;
      break;
  }

  return (
    <List
      {...props}
      sort={{ field: "sortWeight", order: "ASC" }}
      filters={<ResourceFilter />}
      empty={
        <Empty
          resourceLabel={resourceLabels[type]}
          withContactButton={true}
        />
      }
      bulkActionButtons={false}
      exporter={false}
    >
      <Datagrid rowClick={rowClick}>
        <SquareImageField
          source="imagesList.0.url"
          label=""
          sortable={false}
        />
        <TextField source="name" />
        <BooleanField source="listed" sortable={false} />
        <NumberField source="capacity" />
        <ChipField
          label="Space"
          source="space.name"
          link="space.id"
          sortable={false}
        />
        {!hidePricing &&
          <CurrencyField
            source="costPerHour"
            spaceIdSource="space.id"
            label={currencyLabel[type]}
          />
        }
      </Datagrid>
    </List>
  );
};
